<template>
  <div class="report-component-wrapper" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item-1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3 pb-1">
                  <v-row>
                    <v-col cols="12">
                      <v-select multiple
                                v-model="filters.person_types" label="Тип абонента"
                                :items="dispatcher_person_type_select" filled
                                color="grey" hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select multiple
                                v-model="filters.call_types" label="Тип дзвінка"
                                :items="dispatcher_call_type_select" filled
                                color="grey" hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select multiple
                                v-model="filters.priorities" label="Пріоритет"
                                :items="dispatcher_priority_select" filled
                                color="grey" hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-select multiple
                                v-model="filters.statuses" label="Статус"
                                :items="dispatcher_status_select" filled
                                color="grey" hide-details
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-2">
                      <AddressElementSelect
                          label="Об’єднана громада" v-model="filters.amalgamated_hromada_id"
                          filled select_type="amalgamated_hromada"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="py-2">
                      <AddressElementSelect
                          label="Населений пункт" v-model="filters.city_id"
                          :parent_id="filters.amalgamated_hromada_id" :use_parent_id="true"
                          filled select_type="city"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <AddressElementSelect
                          label="Район міста" v-model="filters.city_area_id"
                          :parent_id="filters.city_id" :use_parent_id="true"
                          filled select_type="city-area"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2" v-if="filters.city_area_id">
                      <AddressElementSelect
                          label="Вулиця" v-model="filters.street_id"
                          :parent_id="filters.city_area_id" :use_parent_id="true"
                          filled select_type="street-with-city-area"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2" v-else>
                      <AddressElementSelect
                          label="Вулиця(-ці)" v-model="filters.street_id"
                          :parent_id="filters.city_id" :use_parent_id="true"
                          filled select_type="street"
                      />
                    </v-col>
                    <v-col cols="12" class="py-2">
                      <AddressElementSelect
                          label="Будинок(-ки)" v-model="filters.building_id"
                          :parent_id="filters.street_id" :use_parent_id="true"
                          filled select_type="building"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="filters.building_number" label="Номер будинку"
                                    filled hide-details color="grey"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model="filters.building_number" label="Номер квартири"
                                    filled hide-details color="grey"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model.number="filters.entrance" label="Під'їзд"
                                    filled hide-details color="grey" v-integer
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field v-model.number="filters.floor" label="Поверх"
                                    filled hide-details color="grey" v-integer
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-switch
                          v-model="filters.auto_width"
                          inset
                          hide-details
                          color="success"
                          class="mt-0 ml-2"
                          :label="filters.auto_width ? 'По ширині сторінки (включений)' : 'По ширині сторінки (виключений)'"
                          @change="onFullWidthChange"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3 pb-1">
                  <v-row>
                    <v-col cols="12">
                      <v-card
                          v-for="(item, idx) in grouping"
                          :key="`gr-idx-${idx}`"
                          style="display: flex; align-items: center"
                          class="ma-2 py-0"
                          elevation="3"
                      >
                        <div style="flex: 0 0 60px">
                          <v-checkbox color="success" class="ml-3" v-model="item.on"/>
                        </div>
                        <div style="flex: 1; font-weight: 400; font-size: .96rem">
                          {{ item.text }}
                        </div>
                        <div style="flex: 0 0 80px">
                          <v-btn icon @click="pushUp(item)">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn icon @click="pushDown(item)">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page"
         :class="{'page-a4-landscape-auto': filters.auto_width, 'page-a4-landscape-auto-full': !filters.auto_width}"
         style="height: auto !important; min-height: 215mm"
         :style="`zoom: ${scale / 100}`"
    >
      <div class="report" :style="`${filters.auto_width ? `transform: scale(${zoom}); transform-origin: top left;` : ''}`">
        <table>
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Історія диспетчерських викликів)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 20px;"></div>
          </caption>
          <thead id="header">
            <tr>
              <th rowspan="2" style="min-width: 50px; width: 50px;">№ з/п</th>
              <th rowspan="2" style="min-width: 120px; width: 120px;">Дата</th>
              <th rowspan="2" style="min-width: 110px;">Тип дзвінка</th>
              <th rowspan="2" style="min-width: 90px;">Тип абонента</th>
              <th rowspan="2" style="min-width: 250px; width: 250px">Адреса</th>
              <th rowspan="2" style="min-width: 140px; width: 140px">Виклик від</th>
              <th rowspan="2" style="min-width: 80px; width: 80px">Телефон</th>
              <th rowspan="2" style="min-width: 240px; width: 240px">Суть звернення</th>
              <th rowspan="2" style="min-width: 100px; width: 100px">Статус</th>
              <th rowspan="2" style="min-width: 90px; width: 90px">Пріоритет</th>
              <th rowspan="2" style="min-width: 90px; width: 90px">Диспетчер</th>
              <th rowspan="2" style="min-width: 200px; width: 200px">Коментар</th>
            </tr>
          </thead>
          <tbody v-for="(item, idx) in items"
                 :key="item.index"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-${idx}`"
          >
          <template v-if="!item.items && by_grouping">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow" colspan="12"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-${idx}`)"
                        :id="`button-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping">
            <tr>
              <td style="min-width: 50px; width: 50px;">{{ item.row_number }}</td>
              <td style="min-width: 120px; width: 120px;">{{ item.call_date_time }}</td>
              <td style="min-width: 110px;">{{ item.call_type_name }}</td>
              <td style="min-width: 90px; text-align: left">{{ item.person_type_name }}</td>
              <td style="min-width: 250px; width: 250px; text-align: left">{{ item.address }}</td>
              <td style="min-width: 140px; width: 140px">{{ item.flat_owner }}</td>
              <td style="min-width: 80px; width: 80px">{{ item.phone }}</td>
              <td style="min-width: 240px; width: 240px; text-align: left">{{ item.call_subject }}</td>
              <td style="min-width: 110px; width: 100px">{{ item.status_name }}</td>
              <td style="min-width: 90px; width: 90px">{{ item.priority_name  }}</td>
              <td style="min-width: 90px; width: 90px">{{ item.user_name }}</td>
              <td style="min-width: 200px; width: 200px">{{ item.comments }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="subitem.index"
            >
              <td style="min-width: 50px; width: 50px;">{{ subitem.row_number }}</td>
              <td style="min-width: 120px; width: 120px;">{{ subitem.call_date_time }}</td>
              <td style="min-width: 110px; width: 110px;">{{ subitem.call_type_name }}</td>
              <td style="min-width: 90px; width: 90px; text-align: left">{{ subitem.person_type_name }}</td>
              <td style="min-width: 250px; width: 250px; text-align: left">{{ subitem.address }}</td>
              <td style="min-width: 140px; width: 140px">{{ subitem.flat_owner }}</td>
              <td style="min-width: 80px; width: 80px">{{ subitem.phone }}</td>
              <td style="min-width: 240px; width: 240px; text-align: left">{{ subitem.call_subject }}</td>
              <td style="min-width: 100px; width: 100px">{{ subitem.status_name }}</td>
              <td style="min-width: 90px; width: 90px">{{ subitem.priority_name }}</td>
              <td style="min-width: 90px; width: 90px">{{ subitem.user_name }}</td>
              <td style="min-width: 200px; width: 200px">{{ subitem.comments }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {LANDSCAPE_ZOOM_WIDTH} from "@/utils/reports";
import ReportHouseAccountingComponentMixin from "@/mixins/report_house_accounting";
import {
  dispatcher_call_type_select,
  dispatcher_person_type_select,
  dispatcher_priority_select,
  dispatcher_status_select
} from "@/utils/icons";


export default {
  name: "dispatcher_calls_history",
  mixins: [ReportHouseAccountingComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect')
  },
  computed: {
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      dispatcher_person_type_select,
      dispatcher_call_type_select,
      dispatcher_priority_select,
      dispatcher_status_select,
      zoom_width: LANDSCAPE_ZOOM_WIDTH,
      filters: {
        person_types: [],
        call_types: [],
        priorities: [],
        statuses: [],
        amalgamated_hromada_id: null,
        city_id: null,
        city_area_id: null,
        street_id: null,
        building_id: null,
        entrance: null,
        floor: null,
        building_number: null,
        flat_number: null,
        auto_width: true,
      },
      grouping: [
        { text: 'Дата дзвінка', value: 'call_date', on: true },
        { text: 'Тип дзвінка', value: 'call_type', on: false },
        { text: 'Тип абонента', value: 'person_type', on: false },
        { text: 'Статус', value: 'status', on: false },
        { text: 'Пріоритет', value: 'priority', on: false },
      ]
    }
  },
  methods: {
    generate_rows(items, max_lvl, by_grouping) {
      this.items = Object.freeze(items)
      this.max_lvl = max_lvl
      this.by_grouping = by_grouping
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.dispatcher_calls_history_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `dispatcher_calls_history_${this.formatDate(this.date_start, 'MM_YYYY')}_${this.formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          email: this.email_address,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.dispatcher_calls_history_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.dispatcher_calls_history(payload)
            .then(response => response.data)
            .then(data => {
              this.items = []
              this.expand = false
              this.by_grouping = false
              this.max_lvl = 0
              this.generate_rows(data.rows, data.max_level, data.by_grouping)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(this.max_lvl -1)
                this.getOnFullPage()
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць - має бути заповнений', color: 'error'})
      }
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
    onFullWidthChange(payload) {
      this.$nextTick(() => {
        if (payload) {
          this.getOnFullPage()
        }
      })
    },
  },
  created() {
    reportAPI.report_settings("dispatcher_calls_history")
        .then(response => response.data)
        .then(data => {
          const server_filters = JSON.parse(data)
          Object.keys(this.filters).forEach(key => {
            if (server_filters[key] !== undefined) {
              this.filters[key] = server_filters[key]
            }
          })
          if (server_filters['grouping'] && this.grouping) {
            this.grouping.forEach(g => {
              g.on = !!server_filters.grouping.includes(g.value);
            })
          }
        })
        .finally(() => {
          this.$nextTick(() => this.getOnFullPage())
        })
  }
}
</script>

<style lang="scss" scoped>
</style>